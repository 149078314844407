import React from 'react';
import Log from './log/log';
import Game from './game/game';
import Games from './game/games';
import GameRound from './gameRoundHistory/gameRound';
import getEnvironmentName from './environmentName';
import GameRounds from './gameRoundHistory/gameRounds';
import IncompleteGameRounds from './gameRound/gameRounds';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import preval from 'preval.macro';
let packageJson = require('../package.json');

const App = () => (
  <React.StrictMode>
    <BrowserRouter>
      <div className="container">
        <HeaderNav />
        <main>
          <Routes>
            <Route path="/" element={<Navigate to="/gameRoundHistory" />} />
            <Route path="/games" element={<Games />} />
            <Route path="/games/:gameId" element={<Game />} />
            <Route path="/incompleteGameRounds" element={<IncompleteGameRounds />} />
            <Route path="/gameRoundHistory" element={<GameRounds />} />
            <Route path="/gameRoundHistory/:gameRoundId" element={<GameRound />} />
            <Route path="/log/:gameRoundId" element={<Log />} />
          </Routes>
        </main>
        <footer className="text-center">
          <div>RGS Admin</div>
          <div>@v{packageJson.version}</div>
          <div>Build Date: {preval`module.exports = new Date().toLocaleString()`}</div>
        </footer>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

const HeaderNav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/gameRoundHistory">
        {window.location.hostname === 'localhost' ? 'RGS Admin' : getEnvironmentName(window.location.hostname).toUpperCase() + ' RGS ADMIN'}
      </Link>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/games">
              Games
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/incompleteGameRounds">
              Incomplete Game Rounds
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gameRoundHistory">
              Game Round History
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default App;
